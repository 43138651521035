import { richText, stripRichTextWrapperTag } from '../../utils/html';

export default ({ primary }, _pageData, { $i18n }) => {
  const date = primary.date ? new Date(primary.date) : null;
  const formattedDate = date
    ? new Intl.DateTimeFormat($i18n.locale).format(date)
    : null;

  return {
    date: formattedDate
      ? `${$i18n.t('article.published', { date: formattedDate })}`
      : null,
    readingTime: primary.reading_time
      ? `${$i18n.t('article.reading-time', {
          readingTime: primary.reading_time,
        })}`
      : null,
    title: stripRichTextWrapperTag(primary.title),
    description: richText(primary.description),
    summaryData: primary.summary && {
      withBackground: true,
      links: primary.summary.map((item, index) => {
        return {
          label: item.summary_anchor_label,
          cta: {
            href: `#anchor-${index + 1}`,
            external: 'false',
          },
        };
      }),
    },
  };
};
